export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24')
];

export const server_loads = [];

export const dictionary = {
		"/": [8],
		"/2.0": [9],
		"/account/jwt": [10],
		"/account/login": [11],
		"/account/logout": [12],
		"/directory": [13,[2]],
		"/directory/business/[guid]": [14,[2]],
		"/directory/contact/[guid]": [15,[2]],
		"/directory/create": [16,[2]],
		"/directory/employee/[guid]": [17,[2]],
		"/directory/tools/bulk-tickets": [18,[2,3,4]],
		"/directory/tools/manage-campaigns": [19,[2,3,5]],
		"/directory/tools/state-lien-search": [20,[2,3,6]],
		"/directory/tools/state-lien-search/[state]": [21,[2,3,6]],
		"/directory/tools/ticket-analytics": [22,[2,3]],
		"/survival-guide": [23,[7]],
		"/survival-guide/[slug]": [24,[7]]
	};

export const hooks = {
	handleError: (({ error }) => { console.error(error) }),
};

export { default as root } from '../root.svelte';